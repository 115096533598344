import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import { notifyAnalytics } from "./analytics.resource";

// Integrations
export function getLastSuccessfulSync(id, syncId) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/syncs/${syncId}`);
}

export function getLastSyncInfo(id) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/last-successful-sync`,
  );
}

export function getIntegrations() {
  return fetchAsObservable(`/wg/accounting-integrations`).pipe(
    pluck("integrations"),
  );
}

export function getIntegrationStatus(id, type, syncId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/syncs/${syncId}/status?third_party=${type}`,
  );
}

export function cancelSync(id, syncId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/syncs/${syncId}/cancel`,
    { method: "POST" },
  );
}

export function getSyncPreview(id, syncId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/syncs/${syncId}/preview`,
  );
}

export function patchSettings(id, settings) {
  return fetchAsObservable(`/wg/accounting-integrations/${id}/settings`, {
    method: "PATCH",
    body: settings,
  }).pipe(pluck("integrations"));
}

// Payments
export function startPaymentsSync(id, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/payment-start?third_party=${type}`,
    { method: "POST" },
  );
}

export function updatePaymentsSyncStatus(id, type, enabled = true, user) {
  notifyAnalytics(
    user,
    "qbo",
    "qbo_payments",
    `qbo_global_payment_sync_${enabled ? "resumed" : "paused"}`,
    {},
  );
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/payment-status?third_party=${type}&enabled=${enabled}`,
    { method: "POST" },
  );
}

// clients sync
export function prepareClientSync(id, type, filter) {
  let queryParam = "";
  if (filter) {
    queryParam = `&customer_import_filter=${filter}`;
  }
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/prepare-clients-sync?third_party=${type}${queryParam}`,
    { method: "POST" },
  );
}

export function startClientsSync(
  id,
  type,
  syncId,
  syncUnmatchedThirdPartyClients = true,
) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/syncs/${syncId}/clients-sync?third_party=${type}&sync_unmatched_third_party_to_canopy=${syncUnmatchedThirdPartyClients}`,
    { method: "POST" },
  );
}

export function getClientsUsage() {
  return fetchAsObservable("/api/clients/reached_limit");
}

export function getSubscriptionDetail() {
  return fetchAsObservable("/wg/subscription/detail");
}

// Service Items
export function prepareServiceItemsSync(id, type, parentId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/prepare-items-sync?parentId=${parentId}&third_party=${type}`,
    { method: "POST" },
  );
}

export function startServiceItemsSync(id, type, syncId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/syncs/${syncId}/items-sync?third_party=${type}`,
    { method: "POST" },
  );
}

export function getLateFeeData() {
  return fetchAsObservable(`/api/automation/late_fees`).pipe(
    pluck("late_fees"),
  );
}

export function updateLateFeeData(late_fees) {
  return fetchAsObservable(`/api/automation/late_fees`, {
    method: "PUT",
    body: { late_fees },
  }).pipe(pluck("late_fees"));
}

export function getIncomeAccounts(integrationId, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${integrationId}/income-accounts?third_party=${type}`,
  ).pipe(pluck("accounts"));
}

export function getCategories(integrationId, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${integrationId}/categories?third_party=${type}`,
  ).pipe(pluck("categories"));
}

// integration auth
export function createAuthUrl(type) {
  return fetchAsObservable(`/wg/accounting-integrations?third_party=${type}`, {
    method: "POST",
  }).pipe(pluck("auth_url"));
}

export function disconnectAuthUrl(id, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/disconnect?third_party=${type}`,
    {
      method: "POST",
    },
  );
}

export function getQboMatch(credentialsId, clientId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/clients/${clientId}/match?third_party=qbo`,
  );
}

export function qboMatch(
  credentialsId,
  clientId,
  thirdPartyClientId,
  contactId = "",
) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/clients/${clientId}/match?third_party=qbo&third_party_client_id=${thirdPartyClientId}${contactId ? `&contact_id=${contactId}` : ""}`,
    { method: "POST" },
  ).pipe(pluck("match"));
}

export function qboMatchNew(credentialsId, clientId, contactId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/clients/${clientId}/match?third_party=qbo${contactId ? `&contact_id=${contactId}` : ""}`,
    { method: "POST" },
  );
}

export function qboUnmatch(credentialsId, clientId) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/clients/${clientId}/match?third_party=qbo`,
    { method: "DELETE" },
  );
}

export function validateDisplayName(
  credentialsId,
  displayName,
  validate_in_canopy = true,
) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/clients-validate-display-name?third_party=qbo&validate_in_canopy=${validate_in_canopy}&display_name=${encodeURIComponent(displayName)}`,
    { method: "POST" },
  ).pipe(pluck("is_valid"));
}

export function clientDisplayNameAvailable(displayName) {
  return fetchAsObservable(
    `/api/clients/display_name_available/${encodeURIComponent(displayName)}`,
  ).pipe(pluck("available"));
}

export function qboSearch(credentialsId, searchValue, searchField) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/clients-search?third_party=qbo`,
    {
      method: "POST",
      body: {
        search_field: searchField,
        search_value: searchValue,
      },
    },
  ).pipe(pluck("customers"));
}

export function suggestDisplayName(credentialsId, clientId, displayName) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${credentialsId}/clients/${clientId}/suggest?third_party=qbo`,
    { method: "POST", body: { display_name: displayName } },
  ).pipe(pluck("suggested_display_names"));
}

export function patchClient(id, data) {
  return fetchAsObservable(`/api/clients/${id}`, {
    method: "PATCH",
    body: { clients: data },
  });
}

export function getAvailableContactsForClient(id, clientId, type) {
  return fetchAsObservable(
    `/wg/accounting-integrations/${id}/clients/${clientId}/get-available-contacts?third_party=${type}`,
  ).pipe(pluck("contacts"));
}
