import React, { useMemo } from "react";
import {
  CpIcon,
  CpNameChip,
  CpButton,
  phoneNumber,
} from "canopy-styleguide!sofe";
import { Client, Contact } from "../../../types/client";
import { TContact } from "../../../types/contact";

type Props = {
  client: Client;
  newCrmEnabled: boolean;
  contact: TContact | Contact | undefined;
  showEditContactModal: () => void;
};

export function RowClient(props: Props) {
  const { client, newCrmEnabled, contact, showEditContactModal } = props;

  const clientPrimaryPhone = useMemo(() => {
    return (
      (newCrmEnabled
        ? contact?.phones?.find((phone) => phone.is_primary)?.value
        : client?.phones?.find((phone) => phone.is_primary)?.value) || ""
    );
  }, [client?.phones, newCrmEnabled, contact?.phones]);

  const clientPrimaryEmail = useMemo(() => {
    return (
      (newCrmEnabled
        ? contact?.emails?.find((email) => email.is_primary)?.value || ""
        : client?.emails?.find((email) => email.is_primary)?.value) || ""
    );
  }, [client?.emails, newCrmEnabled, contact?.emails]);

  const clientPrimaryAddress = useMemo(() => {
    return newCrmEnabled
      ? contact?.addresses?.find((address) => address.is_primary)
      : client?.addresses?.find((address) => address.is_primary);
  }, [client?.addresses, newCrmEnabled, contact?.addresses]);

  return (
    <tr>
      <td className="">
        <div className="flex items-center cp-gap-16">
          <CpNameChip initials={client.initials} />{" "}
          {newCrmEnabled
            ? client.display_name
            : client.display_name || client.name}
        </div>
      </td>
      <td className="cp-text-center">
        <CpIcon name="misc-canopy-mark" size={20} />
      </td>
      {newCrmEnabled && (
        <td>
          {client.is_business ? (
            client.business_name
          ) : (
            <div className="cp-flex-center">
              {contact?.name}
              <CpButton
                className="cp-ml-8"
                icon="crud-pencil"
                aria-label="edit"
                onClick={showEditContactModal}
              />
            </div>
          )}
        </td>
      )}
      <td>{phoneNumber(clientPrimaryPhone) || "-"}</td>
      <td>{clientPrimaryEmail || "-"}</td>
      <td>
        <div>{clientPrimaryAddress?.value?.address_1 || "-"}</div>
        {!!clientPrimaryAddress?.value?.address_1 &&
          !!clientPrimaryAddress?.value?.address_2 && (
            <div>{clientPrimaryAddress?.value?.address_2}</div>
          )}
      </td>
      <td>{clientPrimaryAddress?.value?.locality || "-"}</td>
      <td className="">{clientPrimaryAddress?.value?.region || "-"}</td>
    </tr>
  );
}
